import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import HowWeWorkScreen from "../components/HowWeWork/00-Main/main"
import CollaborationScreen from "../components/Services/04-Collaboration/collaboration"
import IndustriesScreen from "../components/Services/02-Industries/industries"
import ContactsScreen from "../components/Home/07-Contacts/contacts"

const HowWeWorkPage = () => (
  <Layout>
    <SEO
      title="Madfish | Software development company experienced in Blockchain and JS web"
      description="We offer full-cycle development (discovery phase and business analysis, software architecture and development, project management, QA, support and launching)"
    />
    <HowWeWorkScreen />
    <CollaborationScreen />
    <IndustriesScreen />
    <ContactsScreen />
  </Layout>
)

export default HowWeWorkPage
