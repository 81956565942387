import React, { useEffect } from "react"
import { StickyContainer, Sticky } from "react-sticky"

// Styles
import styles from "./main.module.sass"

// Icons
import DiscoveryIcon from "../../../icons/howWeWork/discovery.svg"
import MvpVersionIcon from "../../../icons/howWeWork/mvp-version.svg"
import MainWorkProccessIcon from "../../../icons/howWeWork/main-work-proccess.svg"
import TestingIcon from "../../../icons/howWeWork/testing.svg"
import ProjectCompletionIcon from "../../../icons/howWeWork/project-completion.svg"
import SupportIcon from "../../../icons/howWeWork/support.svg"
import TitleIcon from "../../../icons/howWeWork/title.svg"
import TitleActiveIcon from "../../../icons/howWeWork/title-active.svg"

// Content
const howWeWorkArr = [
  {
    id: "discovery",
    title: `Discovery`,
    description: `Discovery or scoping phase is a process of collecting and analyzing information about the project, its intended market, audience. It allows get a well-rounded and in-depth understanding of the goals, scope, and limitations. The business discovery stage helps understand the end-users, their needs, and their requirements. We will clarify how your product or service will work and look.`,
    icon: <DiscoveryIcon />,
  },
  {
    id: "mvp",
    title: `MVP Version`,
    description: `The main purpose of an MVP is to launch a product quickly, based on your idea, and with a small budget. With the help of an MVP, one can find the target market and audience; pull the ideas based on experience, and save time. When we are working with an MVP it is important to collect feedback from the initial users so we can have a better understanding of user’s expectations, and with that information, we can build better features that meet the user’s needs.`,
    icon: <MvpVersionIcon />,
  },
  {
    id: "main",
    title: `Main work <span>process</span>`,
    description: `You already have a minimal viable product - it’s time to build a full version. We will analyze customer feedback and develop the full functionality and interface with award-winning developers. On this stage front-end and back-end coding will be completed, database is connected and the various algorithms are implemented with least time and space complexities.`,
    icon: <MainWorkProccessIcon />,
  },
  {
    id: "test",
    title: `Testing`,
    description: `After completing the coding stage, the product is sent to the testing department. The work of testers plays a crucial role in the quality of software and its performance. Quality Analysts test software using various test cases and several devices. On this stage, a product needs verification which includes software testing and debugging. When the testing department ensures that software is error-free, it goes to the next stage.`,
    icon: <TestingIcon />,
  },
  {
    id: "completion",
    title: `<span>Project </span>completion`,
    description: `Finally, the software is handed over to the users to be launched. If the client needs any modification or updates, the product is to come under the maintenance / support process. The featured stages of software development procedure are followed by the majority of IT companies in order to provide high-quality services in the development of all sorts of software. Feel free to contact us for more details!`,
    icon: <ProjectCompletionIcon />,
  },
  {
    id: "support",
    title: `Support`,
    description: `During the technical support, we take responsibility for scaling up your product. It is our task to absorb those shocks from users and adjust service on the fly. We offer an extensive support package, which includes dedicated customer support and a SLA to keep all modules on the product running smoothly and bug fixing.`,
    icon: <SupportIcon />,
  },
]

const HowWeWorkScreen = () => {
  let distance
  let oldElement = "",
    newElement,
    allElements = ["discovery"]

  const getCoords = elem => {
    const box = elem.getBoundingClientRect()

    return box.top + window.pageYOffset
  }

  useEffect(() => {
    const mvpEl = document.querySelector("#mvp")
    const mainEl = document.querySelector("#main")
    const testEl = document.querySelector("#test")
    const completionEl = document.querySelector("#completion")
    const supportEl = document.querySelector("#support")

    const handleScroll = () => {
      if (document.querySelector(`.${styles.progressOuter}`)) {
        setTimeout(() => {
          distance = window.pageYOffset
          switch (true) {
            case distance < getCoords(mvpEl) - 168:
              newElement = "discovery"
              allElements = ["discovery"]
              break
            case distance < getCoords(mainEl) - 168:
              newElement = "mvp"
              allElements = ["discovery", "mvp"]
              break
            case distance < getCoords(testEl) - 168:
              newElement = "main"
              allElements = ["discovery", "mvp", "main"]
              break
            case distance < getCoords(completionEl) - 168:
              newElement = "test"
              allElements = ["discovery", "mvp", "main", "test"]
              break
            case distance < getCoords(supportEl) - 168:
              newElement = "completion"
              allElements = ["discovery", "mvp", "main", "test", "completion"]
              break
            default:
              newElement = "support"
              allElements = [
                "discovery",
                "mvp",
                "main",
                "test",
                "completion",
                "support",
              ]
          }

          if (newElement !== oldElement) {
            document.querySelectorAll(`.${styles.title}`).forEach(el => {
              el.classList.remove(styles.activeTitle)
            })
            allElements.forEach(el => {
              document
                .querySelector(`#${el}-title`)
                .classList.add(styles.activeTitle)
            })

            const distanceFromLeft = parseInt(
              document
                .querySelector(`#${newElement}-title .${styles.circleInner}`)
                .getBoundingClientRect().x -
                document.querySelector(`.${styles.bar}`).getBoundingClientRect()
                  .x,
              10
            )
            document.querySelector(
              `.${styles.inner}`
            ).style.width = `${distanceFromLeft}px`

            oldElement = newElement
          }

          // Reset calculations
          distance = null
        }, 66)
      }
    }

    handleScroll()

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  const handleScroll = elem => {
    window.scrollTo({
      top: getCoords(document.querySelector(elem)) - 168,
      behavior: "smooth",
    })
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <h1>How we are working</h1>
        </div>
      </div>
      <StickyContainer>
        <Sticky>
          {({ style }) => (
            <div
              style={{ ...style, top: `70px` }}
              className={styles.progressOuter}
            >
              <div className={styles.progress}>
                <div className="container">
                  <div className="row v-center space-between flex-wrap">
                    <div className={styles.titles}>
                      {howWeWorkArr.map(({ id, title }, index) => (
                        <div className={styles.title} id={id + "-title"}>
                          <div className={styles.titleInner}>
                            <span
                              className={
                                styles.background + " " + styles.notactive
                              }
                            >
                              <TitleIcon />
                            </span>
                            <span
                              className={
                                styles.background + " " + styles.active
                              }
                            >
                              <TitleActiveIcon />
                            </span>
                            <button
                              className={styles.titleText}
                              onClick={() => handleScroll(`#${id}`)}
                              dangerouslySetInnerHTML={{ __html: title }}
                            ></button>
                          </div>
                          <div className={styles.circle}>
                            <div className={styles.circleInner}></div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={styles.bar}>
                      <div className={styles.inner}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Sticky>
        <div className="container">
          <div className="row v-center space-between flex-wrap">
            <div className={styles.content}>
              {howWeWorkArr.map((el, index) => (
                <div className={styles.row} key={el.id} id={el.id}>
                  <div className={styles.text}>
                    <h3>{el.title.replace(/<\/?span[^>]*>/g, "")}</h3>
                    <p>{el.description}</p>
                  </div>
                  <div className={styles.icon}>{el.icon}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </StickyContainer>
    </section>
  )
}

export default HowWeWorkScreen
